<template>
  <el-dialog
    :title="dataForm.userId?'修改':'新增'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="800px"
  >
    <el-form
      ref="dataForm"
      :rules="dataRule"
      :model="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item
        label="用户名称"
        prop="nickName"
      >
        <el-input
          v-model="dataForm.nickName"
          placeholder="请输入用户名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="登录名称"
        prop="username"
      >
        <el-input
          v-model="dataForm.username"
          placeholder="请输入登录名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="登录密码"
        prop="password"
        v-if="!dataForm.userId"
      >
        <el-input
          v-model="dataForm.password"
          placeholder="请输入登录密码"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="手机号"
        prop="mobile"
      >
        <el-input
          v-model="dataForm.mobile"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="邮箱"
        prop="email"
      >
        <el-input
          v-model="dataForm.email"
          placeholder="请输入邮箱"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="头像"
        prop="avatar"
      >
        <el-upload
          class="avatar-uploader width50height50"
          :action="$store.state.uploadImgUrl"
          :show-file-list="false"
          :on-success="uploadSuccess"
          :on-error="this.$uploadError"
          :before-upload="this.$beforeUploadImage"
          accept="image/*"
        >
          <img
            style="background-color:#999;display: block;"
            v-if="dataForm.avatar"
            :src="dataForm.avatar"
          >
          <i
            v-else
            class="el-icon-plus avatar-uploader-icon"
          ></i>
        </el-upload>
      </el-form-item>
      <el-form-item
        label="状态"
        prop="status"
      >
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="1">正常</el-radio>
          <el-radio :label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible=false">取 消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
      >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {},
      dataRule: {
        nickName: [
          { required: true, message: "该项为必填项", trigger: "blur" },
        ],
        username: [
          { required: true, message: "该项为必填项", trigger: "blur" },
        ],
        username: [
          { required: true, message: "该项为必填项", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    init(id) {
      this.visible = true;
      if (id) {
        this.$http.get(`admin/sys-user/baseGetById/${id}`).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            delete data.password;
            if (data) this.dataForm = data;
          }
        });
      } else {
        let type = 2;
        try {
          type = JSON.parse(localStorage.getItem("predicthealthUserInfo"))
            .type;
        } catch (err) {}
        this.dataForm = {
          status: 1,
          type: type,
        };
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let url = "admin/sys-user/saveOrUpdateUser";
          let data = this.dataForm;
          this.$http.post(`${url}`, data).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.visible = false;
              this.$emit("refreshDataList");
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          });
        }
      });
    },
    /**
     * 上传图片成功
     */
    uploadSuccess(res) {
      this.$store.state.beforeUploadLoading.close();
      this.$set(this.dataForm, "avatar", res.data);
    },
  },
};
</script>

<style lang='less' scoped>
</style>
